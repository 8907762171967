<template lang="pug">
div
  v-container( fill-height fluid grid-list-xl )
    v-layout( justify-center wrap )
      v-flex( row no-gutter md12 )
        v-flex(class="align-content-center")
          v-autocomplete(
            dense
            outlined
            class="pt-2"
            label="Cliente"
            item-value="idcliente"
            :items="clientes"
            :item-text="item => item.nome"
            no-data-text="Nenhum registro disponível"
            v-model="cliente"
            :loading="loadingClientes"
          )
        v-flex( md2  class="align-content-center")
          v-text-field(
            dense
            outlined
            class="pt-2"
            type="datetime-local"
            label="Data e hora inicial"
            v-model="dataInicial"
          )
        v-flex( md2  class="align-content-center")
          v-text-field(
            dense
            outlined
            class="pt-2"
            type="datetime-local"
            label="Data e hora final"
            v-model="dataFinal"
          )
        v-flex( md1 class="align-content-center")
          v-autocomplete(
            dense
            outlined
            class="pt-2"
            label="Módulo"
            return-object
            :item-text="item => item.text"
            :items="[{text: 'CSServer', value: 7}, {text: 'CSEmissor', value: 11}, {text: 'CSConnect', value: 28}]"
            v-model="modulo"
            :onchange="setTitle(`Logs - ${ modulo.text }`)"
          )

        div(class="pt-4")
          v-btn(

            :disabled="!cliente"
            color="primary"
            @click="getLogs"
          ) Buscar
            v-icon mdi-magnify

      v-flex( md12 )
        v-row(
          no-gutter
          class="d-flex justify-center mx-2"
        )
          v-text-field(
            dense
            outlined
            class="mx-1 pt-2"
            label="Pesquisar"
            v-model="search"
            @change="getLogs"
          )

          div(md1)
            v-select(
              class="mx-1 pt-2"
              dense
              outlined
              label="Itens por página"
              v-model="itemsPerPage"
              :items="[50, 100, {text:'Todos',value: 0}]"
              @change="getLogs"
            )
          div(md1 class="mx-1 pt-4")
            b {{ recordsShowing }}

          v-pagination(
            class="mx-1 pt-1"
            v-model="page"
            :length="totalPages"
            :total-visible="5"
            @input="getLogs"
          )
        v-divider

        v-data-table(
          class="mb-10"
           dense
          :headers="header"
          :items="logs",
          no-data-text="Nenhum registro encontrado"
          disable-pagination
          hide-default-footer
        )
          template( v-slot:item="{ item }" )
            tr( v-bind:class="item.level +' ml-1 mx-2'" )
              td {{ getDateTime(item.timestamp) }}
              td {{ item.level }}
              td {{ item.message }}

</template>

<script>
import { mapMutations } from 'vuex';
import { server } from '@/api/';
import csapi from '@/api/csapi';

export default {
  data: () => ({
    search: null,
    page: 1,
    itemsPerPage: 50,
    loadingClientes: false,
    clientes: [],
    cliente: null,
    logs: [],
    recordCount: 0,
    recordsShowing: '',
    totalPages: 0,
    dataInicial: `${new Date().toISOString().split('T')[0]} 00:00`,
    dataFinal: `${new Date().toISOString().split('T')[0]} 23:59`,
    modulo: { text: 'CSServer', value: 7 },
    header: [
      {
        text: 'Data',
        align: 'start',
        sortable: true,
        value: 'timestamp'
      },
      { text: 'Nível', value: 'level' },
      { text: 'Mensagem', value: 'message' }
    ]

  }),

  async mounted() {
    this.isControl(true);
    this.setTitle(`Logs - ${this.modulo.text}`);

    server.refreshAuthProd(this.$store.state.auth);
    this.clientes = await server.getClientes();
    this.clientes = this.clientes.sort((a, b) => {
      if (a.nome < b.nome) return -1;
      if (a.nome > b.nome) return 1;
      return 0;
    });
  },

  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),

    getDateTime(datetime) {
      const [date, time] = datetime.split('T');
      const [year, month, day] = date.split('-');
      const [hour, minute, second] = time.split('.')[0].split(':');
      return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    },

    async getLogs() {
      this.showLoader('Carregando Logs...');
      try {
        const res = await csapi.get(
          `/v1/server/${this.cliente}/logs?modulo=${this.modulo.value}&datainicial=${this.dataInicial}&datafinal=${this.dataFinal}&page=${this.page}&limit=${this.itemsPerPage}${this.search ? `&term=${this.search}` : ''}`
        );

        const { logs, count } = res.data;

        // calcula o total de páginas
        let totalPages = count / this.itemsPerPage;
        totalPages = totalPages < 1 ? 1 : totalPages;
        this.totalPages = Math.ceil(totalPages);
        this.recordCount = count;
        this.recordsShowing = `${this.page * this.itemsPerPage - this.itemsPerPage + 1} - ${this.page * this.itemsPerPage > count ? count : this.page * this.itemsPerPage} de ${count}`;
        this.logs = logs;
      } finally {
        this.hideLoader();
      }
    },

    getClients() {
      this.loadingClientes = true;
      server.getClientes()
        .then((res) => {
          return this.clientes = res;
        })
        .finally(() => {
          this.loadingClientes = false;
        });
    },

  },

};
</script>

<style scoped>

.Verbose,
.Debug {
  color: #363636
}

.Information {
  color: #1976d2;
}

.Warning {
  -webkit-text-stroke: 0.6px #ffca28;
}

.Error,
.Faltal {
  -webkit-text-stroke: 0.6px red;
}
</style>
